<template>
  <b-modal
    id="modal-active-package"
    title="Kích hoạt gói dịch vụ"
    size="xl"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <div class="py-4">
          <b-row class="mb-2">
            <b-col>
              <label for="activeDate" class="mb-0">Ngày kích hoạt</label>
              <date-picker
                placeholder="--- Chọn ngày kích hoạt ---"
                name="brithday"
                required
                input-class="form-control"
                type="date"
                format="DD/MM/YYYY"
                value-type="format"
                class="mt-2"
                :disabled-date="disabledBeforeTodayAndAfterTwoWeek"
                v-model="activeDate"
              />
            </b-col>
          </b-row>
          <div class="mb-4">
            Số khách hàng đã chọn:
            {{ selectedItems.length ? selectedItems.length : 1 }}
          </div>
          <template-table
            :column="column"
            :data="selectedItems.length ? selectedItems : [customer]"
            :tableAction="false"
            :selectAction="false"
            :searchAction="false"
          >
            <template v-slot:body="{ item }">
              <td>{{ item.fullName }}</td>
              <td>
                {{ item.activePackageName }}
              </td>
              <td>
                {{
                  item.activePackage &&
                  convertTimestampToDate(item.activePackage.expirationDate)
                }}
              </td>
              <td>{{ item.packageName }}</td>
            </template>
          </template-table>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-4">
          <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
            Hủy
          </b-button>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Kích hoạt
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import DatePicker from 'vue2-datepicker';
export default {
  name: 'ModalActivePackage',
  components: {
    'date-picker': DatePicker,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    customer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeDate: this.$moment(new Date()).format('DD/MM/YYYY'),
      loading: false,
      column: [
        {
          key: 'name',
          label: 'Khách hàng',
        },
        {
          key: 'activePackage',
          label: 'Gói dịch vụ đang dùng',
        },
        {
          key: 'dueDate',
          label: 'Thời gian kết thúc',
        },
        {
          key: 'newPackage',
          label: 'Gói dịch vụ mới ',
        },
      ],
    };
  },
  methods: {
    resetModal() {
      this.activeDate = this.$moment(new Date()).format('DD/MM/YYYY');
      this.$emit('reset');
    },
    disabledBeforeTodayAndAfterTwoWeek(date) {
      const today = this.$moment().add(-15, 'day');
      return date < today;
    },
    onClickCancelButton() {
      this.$bvModal.hide('modal-active-package');
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const params = {
          activeDate: this.convertDateToTimestamp(this.activeDate),
        };
        if (this.selectedItems.length) {
          params['ids'] = this.selectedItems.map((el) => el.id);
        } else {
          params['id'] = this.customer.id;
        }
        const { meta, error } = await this.$api.post(
          `/PackageAccountTransaction/${
            this.selectedItems.length ? 'ActivePackageList' : 'ActivePackage'
          }`,
          params,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.resetModal();
        this.$emit('active-success');
        this.onClickCancelButton();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang=""></style>
